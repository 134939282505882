import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <div style={article.itemType !== 'Wedding Invite' ? {padding: '5rem'} : {}} className={styles.preview}>
    <div style={article.itemType === 'Wedding Invite' ? {height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'} : {}}>
      {
        article.thumbnail?.fixed ?
        <Img alt="" style={{border: '1px solid #33333320', width: '350px'}} fixed={article.thumbnail.fixed} /> :
        <Img alt="" fluid={article.thumbnail.fluid} />
      }

    </div>
    <Link to={`/${article.slug}`}>
      <div className={styles.details}>
        <h3 className={styles.previewTitle}>{article.title}</h3>
      </div>
    </Link>
  </div>
)
